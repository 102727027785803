import { useContext, useRef, useState } from "react";
import { ICourse } from "../../interfaces";
import { Container, Button, H2, Input, TextEditor, EditorMethods, ActivityIndicator, NumericInput  } from "typetailui";
import { addCourse } from "../../networking";
import GlobalContext from "../../helpers/context";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmptyOrWhitespace } from "../../helpers/utils";
import { EditStatus } from "../../interfaces/EditStatus";

export default function AddCourse(){
  const [course, setCourse] = useState<ICourse>({name: "", code: "", order: 0, status: EditStatus.Draft});
  const {state} = useContext(GlobalContext);
  const editorRef = useRef<EditorMethods>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const onAdd = async () => {
    try {
      if (
        isEmptyOrWhitespace(course.name) ||
        isEmptyOrWhitespace(course.code) ||
        isEmptyOrWhitespace(String(course.order))
      ) {
        return alert("Please input name, code, and order.");
      }
      const obj = {...course};
      if(editorRef.current){
        const description = {
          data: editorRef.current.getEditorContent(),
          text: editorRef.current.getEditorText()
        }
        obj.description = description;
      }
      const ret = await addCourse(state.user.token, location.state?.programId, obj);
      if(ret && ret.success){
        alert("Course is added successfully.");
        navigate(-1);
      }else{
        alert("Cannot add this course. Please try again later");
      }
    } catch (error) {
      alert("Cannot add this course. Please try again later");
    }
  }
  const goBack = () => {
    navigate(-1);
  }
  if(state.loading){
    return <ActivityIndicator/>
  }
  return(
    <Container className="justify-center space-y-1">
      <H2 className="text-center">Add New Course</H2>
      <Input placeholder="Name" onChangeText={text => setCourse({...course, name: text})} value={course.name} type="text"/>
      <Input placeholder="Code" onChangeText={text => setCourse({...course, code: text})} value={course.code} type="text"/>
      <NumericInput onChangeNumber={number => setCourse({...course, order: number})} placeholder="Order" handleEnterKey={onAdd}/>
      <TextEditor ref={editorRef}/>
      <Container className="flex flex-row justify-center">
        <Button title="Back" onClick={goBack} className="mx-2"/>
        <Button title="Add" onClick={onAdd}/>
      </Container>
    </Container>
  )
}