import { IResource, IResponse } from "../interfaces";
import request from "./common";
export async function getResources(token: string, courseId: string, moduleId: string): Promise<IResponse | null>{
  try {
    const url = `/courses/${courseId}/modules/${moduleId}/resources`;
    const res = await request(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}
export async function getResource(token: string, courseId: string, moduleId: string, resourceId: string): Promise<IResponse | null>{
  try {
    const url = `/courses/${courseId}/modules/${moduleId}/resources/${resourceId}`;
    const res = await request(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}
export async function createResource(token: string, courseId: string, moduleId: string, resource: IResource): Promise<IResponse | null>{
  try {
    const url = `/courses/${courseId}/modules/${moduleId}/resources`;
    const res = await request(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      },
      body: JSON.stringify(resource)
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}
export async function deleteResource(token: string, courseId: string, moduleId: string, resourceId: string): Promise<IResponse | null>{
  try {
    const url = `/courses/${courseId}/modules/${moduleId}/resources/${resourceId}`;
    const res = await request(url, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function updateResource(token: string, courseId: string, moduleId: string, resource: IResource): Promise<IResponse | null>{
  try {
    const url = `/courses/${courseId}/modules/${moduleId}/resources/${resource._id}`;
    const res = await request(url, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      },
      body: JSON.stringify(resource)
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}
