import { RouterProvider, createBrowserRouter } from "react-router-dom";
import React, { Suspense, lazy } from "react";

import AddProgram from "../components/programs/AddProgram";
import AddCourse from "../components/courses/AddCourse";
import AddModule from "../components/modules/AddModule";
import AddResource from "../components/resources/AddResource";

import AddQuestion from "../components/questions/AddQuestion";
import ProgramDetails from "../components/programs/ProgramDetails";
import { ActivityIndicator } from "typetailui";
import CourseDetails from "../components/courses/CourseDetails";
import ModuleDetails from "../components/modules/ModuleDetails";
import QuestionDetails from "../components/questions/QuestionDetails";
import ResourceDetails from "../components/resources/ResourceDetails";
import UpdateProgram from "../components/programs/UpdateProgram";
import UpdateCourse from "../components/courses/UpdateCourse";
import UpdateModule from "../components/modules/UpdateModule";
import UpdateResource from "../components/resources/UpdateResource";
import UpdateQuestion from "../components/questions/UpdateQuestion";
import NotFoundError from "../components/NotFoundError";
import AddUser from "../components/auth/AddUser";
import UploadQuestions from "../components/questions/UploadQuestions";
import AddClass from "../components/class/AddClass";
import UpdateClass from "../components/class/UpdateClass";

const ProgramList = lazy(() => import("../components/programs/ProgramList"));
const ResourceList = lazy(() => import("../components/resources/ResourceList"));
const QuestionList = lazy(() => import("../components/questions/QuestionList"));
const CourseList = lazy(() => import("../components/courses/CourseList"));
const ModuleList = lazy(() => import("../components/modules/ModuleList"));
const ClassList = lazy(() => import("../components/class/ClassList"));

const router = createBrowserRouter([
  {
    path: "",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <ProgramList />
      </Suspense>
    )
  },
  {
    path: "/add-program",
    element: <AddProgram />
  },
  {
    path: "/program-details",
    element: <ProgramDetails />
  },
  {
    path: "/update-program",
    element: <UpdateProgram />
  },
  {
    path: "/courses/:programId",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <CourseList />
      </Suspense>
    )
  },
  {
    path: "/course-details",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <CourseDetails />
      </Suspense>
    )
  },
  {
    path: "/update-course",
    element: <UpdateCourse />
  },
  {
    path: "/add-course",
    element: <AddCourse />
  },
  {
    path: "/modules/:programId/:courseId",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <ModuleList />
      </Suspense>
    )
  },
  {
    path: "/module-details",
    element: <ModuleDetails />
  },
  {
    path: "/update-module",
    element: <UpdateModule />
  },
  {
    path: "/add-module",
    element: <AddModule />
  },
  {
    path: "/resources/:courseId/:moduleId",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <ResourceList />
      </Suspense>
    )
  },
  {
    path: "/resource-details",
    element: <ResourceDetails />
  },
  {
    path: "/add-resource",
    element: <AddResource />
  },
  {
    path: "/update-resource",
    element: <UpdateResource />
  },
  {
    path: "/questions/:resourceId",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <QuestionList />
      </Suspense>
    )
  },
  {
    path: "/question-details",
    element: <QuestionDetails />
  },
  {
    path: "/update-question",
    element: <UpdateQuestion />
  },
  {
    path: "/add-question",
    element: <AddQuestion />
  },
  {
    path: "/upload-question",
    element: <UploadQuestions />
  },
  {
    path: "/add-user",
    element: <AddUser />
  },
  {
    path: "/classes",
    element: (
      <Suspense fallback={<ActivityIndicator />}>
        <ClassList />
      </Suspense>
    )
  },
  {
    path: "/add-class",
    element: <AddClass />
  },
  {
    path: "/update-class",
    element: <UpdateClass />
  },
  {
    path: "*",
    element: <NotFoundError />
  }
]);

export default function Home() {
  return <RouterProvider router={router} />;
}
