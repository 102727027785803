import { IDescription } from "./IDescription";

export enum ProgressType {
  //sequence: init -> progressing -> complete
  Init = "initial",
  Progressing = "progressing",
  Complete = "completed",
  Failed = "failed",
  Canceled = "Canceled" //if users want to enroll again
}

export interface IClass {
  _id?: any;
  title: string; 
  mentorId?: string;
  studentIds?: string[];
  programId?: string;
  startDate?: string;
  endDate?: string;
  schedule?: {
    day: string; // Day of the week
    time: string; // Time of the class
  }[];
  description?: IDescription;
  materials?: string[]; 
  status?: ProgressType;//initial => progressing => completed
}