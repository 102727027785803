import { useContext, useRef, useState } from "react";
import { ICourse } from "../../interfaces";
import { Container, Button, H2, Input, TextEditor, EditorMethods, ActivityIndicator, RadioButton, NumericInput, GroupBox  } from "typetailui";
import { updateCourse } from "../../networking";
import GlobalContext from "../../helpers/context";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmptyOrWhitespace } from "../../helpers/utils";
import { EditStatus } from "../../interfaces/EditStatus";

export default function UpdateCourse(){
  const location = useLocation();
  const [course, setCourse] = useState<ICourse>(location.state.data);

  const {state} = useContext(GlobalContext);
  const editorRef = useRef<EditorMethods>(null);
  const navigate = useNavigate();
  const onUpdate = async () => {
    try {
      if (
        isEmptyOrWhitespace(course.name) ||
        isEmptyOrWhitespace(course.code) ||
        isEmptyOrWhitespace(String(course.order))
      ) {
        return alert("Please input name, code, and order.");
      }
      const obj = {...course};
      if(editorRef.current){
        const description = {
          data: editorRef.current.getEditorContent(),
          text: editorRef.current.getEditorText()
        }
        obj.description = description;
      }
      const ret = await updateCourse(state.user.token, location.state?.programId, obj);
      if(ret && ret.success){
        alert("This course is updated successfully.");
        navigate(-1);
      }else{
        alert("Cannot update this course. Please try again later");
      }
    } catch (error) {
      alert("Cannot update this course. Please try again later");
    }
  }

  const statuses = Object.values(EditStatus).map(status => ({
    value: status,
    label: status
  }));
  const changeStatus = (index: number) => {
    setCourse({...course, status: statuses[index].value})
  }

  if(state.loading){
    return <ActivityIndicator/>
  }
  return(
    <Container className="justify-center space-y-1">
      <H2 className="text-center">Update Course {course.code}</H2>
      <Input placeholder="Name" onChangeText={text => setCourse({...course, name: text})} value={course.name} type="text"/>
      <NumericInput onChangeNumber={number => setCourse({...course, order: number})} placeholder="Order" initial={String(course.order)} handleEnterKey={onUpdate}/>
      <GroupBox title="Status">
        {statuses.map((status, index) =>
            <RadioButton
              key={index}
              index={index}
              name={"status"}
              selected={status.value === course.status}
              handleSelectionChange={index => changeStatus(index)}
              label={status.label}
            />
        )}
        </GroupBox>
      <TextEditor ref={editorRef} initial={course.description?.data}/>
      <Container className="flex flex-row justify-center">
        <Button title="Back" onClick={() => navigate(-1)} className="mx-2"/>
        <Button title="Update" onClick={onUpdate}/>
      </Container>
    </Container>
  )
}

