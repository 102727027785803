import { useContext } from "react";
import { deleteModule } from "../../networking";
import { Container, TextEditor, Button, Text, H4 } from "typetailui";
import GlobalContext from "../../helpers/context";
import { useLocation, useNavigate } from "react-router-dom";

export default function ModuleDetails() {
  const { state } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const {data, course} = location.state;

  const handleDeleteModule = async () => {
    try {
      const ret = await deleteModule(
        state.user.token,
        data.courseId,
        data._id!
      );
      if (ret.success) {
        alert("The module is removed successfully.");
        navigate(-1);
      } else {
        alert("Cannot delete this module. Please try again later.");
      }
    } catch (error) {
      alert("Cannot delete this module. Please try again later.");
    }
  };
  const gotoResourceList = () => {
    navigate(`/resources/${data.courseId}/${data._id}`);
  };
  return (
    <Container className="border-2 m-2 rounded">
      <H4>{`${course.code}: ${course.name}`}</H4>
      <Text>{data.title}</Text>
      <Text>Priority: {data.order}</Text>
      {data.description &&
        <TextEditor readOnly={true} initial={data.description?.data} />}
      <Container className="flex flex-row justify-center space-x-2">
        <Button title="Back" onClick={() => navigate(-1)} />
        <Button title="Resources" onClick={gotoResourceList} />
        <Button title="Delete" onClick={handleDeleteModule} />
      </Container>
    </Container>
  );
}
