import { useContext, useEffect, useRef, useState } from "react";
import { Button, Container, H2, Input, TextEditor, EditorMethods, ActivityIndicator, RadioButton } from "typetailui";
import { IProgram, LANGUAGE } from "../../interfaces";
import { addProgram } from "../../networking";
import GlobalContext from "../../helpers/context";
import { useNavigate } from "react-router-dom";
import { isEmptyOrWhitespace } from "../../helpers/utils";
import { EditStatus } from "../../interfaces/EditStatus";

export default function AddProgram(){
  const [loading, setLoading] = useState(true);
  const [program, setProgram] = useState<IProgram>({name: "", code: "",  language: LANGUAGE.EN, status: EditStatus.Draft});
  const {state} = useContext(GlobalContext);
  const editorRef = useRef<EditorMethods>(null);
  const navigate = useNavigate();
  const onAdd = async () => {
    try {
      if (
        isEmptyOrWhitespace(program.name) ||
        isEmptyOrWhitespace(program.code)
      ) {
        return alert("Please input name and code.");
      }
      setLoading(true);
      const obj = {...program};
      if(editorRef.current){
        const description = {
          data: editorRef.current.getEditorContent(),
          text: editorRef.current.getEditorText()
        }
        obj.description = description;
      }
      const ret = await addProgram(state.user.token, obj);
      if(ret && ret.success){
        alert("Program is added successfully.");
        navigate(-1);
      }else{
        alert("Cannot add this program. Please try again later");
      }
    } catch (error) {
      alert("Cannot add this program. Please try again later");
    }
    setLoading(false);
  }
  const goBack = () => {
    navigate(-1);
  }
  useEffect(() => {
    setLoading(false);
  }, [])
  if(loading){
    return <ActivityIndicator/>
  }
  const languages = Object.values(LANGUAGE).map(language => ({
    value: language,
    label: language
  }));
  const changeLanguage = (index: number) => {
    setProgram({...program, language: languages[index].value})
  }
  return(
    <Container className="justify-center space-y-1">
      <H2 className="text-center">Add New Program</H2>
      <Input placeholder="Name" onChangeText={text => setProgram({...program, name: text})} value={program.name} type="text"/>
      <Input placeholder="Code" onChangeText={text => setProgram({...program, code: text})} value={program.code} type="text" handleEnterKey={onAdd}/>
      {languages.map((language, index) =>
            <RadioButton
              key={index}
              index={index}
              name={"language"}
              selected={language.value === program.language}
              handleSelectionChange={index => changeLanguage(index)}
              label={language.label}
            />
        )}
      <TextEditor ref={editorRef}/>
      <Container className="flex flex-row justify-center">
        <Button title="Back" onClick={goBack} className="mx-2"/>
        <Button title="Add" onClick={onAdd}/>
      </Container>
    </Container>
  )
}