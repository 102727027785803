import { IResponse, IClass, ProgressType } from "../interfaces";
import request from "./common";
export async function addClass(obj: IClass, token: string): Promise<IResponse>{
  try {
    const url = "/classes";
    const res = await request(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(obj)
    });
    return res;
  } catch (error) {
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function getAllClasses(token: string): Promise<IResponse>{
  try {
    const url = "/classes";
    const res = await request(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}


export async function updateClassStatus(classId: string, status: ProgressType, token: string): Promise<IResponse>{
  try {
    const url = `/classes/${classId}`;
    const res = await request(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({status})
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function startClass(classId: string, token: string): Promise<IResponse>{
  try {
    const url = `/classes/${classId}/start`;
    const res = await request(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function endClass(classId: string, token: string): Promise<IResponse>{
  try {
    const url = `/classes/${classId}/end`;
    const res = await request(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}
