import { useState, useRef, useContext } from "react";
import { Container, H2, Input, NumericInput, Button, TextEditor, EditorMethods, ActivityIndicator  } from "typetailui";
import { useLocation, useNavigate} from "react-router-dom";
import GlobalContext from "../../helpers/context";
import { IModule } from "../../interfaces";
import { updateModule } from "../../networking";
import { isEmptyOrWhitespace } from "../../helpers/utils";
export default function UpdateModule(){
  const {state} = useContext(GlobalContext);
  const navigate = useNavigate();
  const editorRef = useRef<EditorMethods>(null);
  const location = useLocation();
  const {data, course} = location.state;
  const [module, setModule] = useState<IModule>(data);
  
  const goBack = () => {
    navigate(-1);
  }
  const onUpdate = async () => {
    try {
      if (
        isEmptyOrWhitespace(module.title) ||
        isEmptyOrWhitespace(String(module.order))
      ) {
        return alert("Please input title and order.");
      }
      const obj = {...module};
      if(editorRef.current){
        const description = {
          data: editorRef.current.getEditorContent(),
          text: editorRef.current.getEditorText()
        }
        obj.description = description;
      }
      const ret = await updateModule(state.user.token, obj);
      if(!ret || !ret.success){
        alert("Cannot update this course. Please try again later");
      }else{
        alert("Module is updated successfully.");
        navigate(-1);
      }
    } catch (error) {
      alert("Cannot add this course. Please try again later");
    }
  }
  if(state.loading){
    return <ActivityIndicator/>
  }
  return(
    <Container className="justify-center space-y-1">
      <H2 className="text-center">Course: {course?.name} ({course?.code})</H2>
      <H2 className="text-center">Update Module</H2>
      <Input placeholder="Title" onChangeText={text => setModule({...module, title: text})} value={module.title} type="text"/>
      <NumericInput onChangeNumber={number => setModule({...module, order: number})} initial={String(module.order)} placeholder="Order" handleEnterKey={onUpdate}/>
      <TextEditor ref={editorRef} initial={module.description?.data}/>
      <Container className="flex flex-row justify-center space-x-2">
        <Button title="Back" onClick={goBack}/>
        <Button title="Update" onClick={onUpdate}/>
      </Container>
    </Container>
  )
}