import { IQuestion, IResponse } from "../interfaces";
import request from "./common";

export async function getQuestions(token: string, resourceId: string): Promise<IResponse>{
  try {
    const url = `/resources/${resourceId}/questions`;
    const res = await request(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function createQuestion(token: string, resourceId: string, question: IQuestion): Promise<IResponse | null>{
  try {
    const url = `/resources/${resourceId}/questions`;
    const res = await request(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      },
      body: JSON.stringify(question)
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function deleteQuestion(token: string, resourceId: string, questionId: string): Promise<IResponse | null>{
  try {
    const url = `/resources/${resourceId}/questions/${questionId}`;
    const res = await request(url, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function updateQuestion(token: string, question: IQuestion): Promise<IResponse | null>{
  try {
    const url = `/resources/${question.resourceId}/questions/${question._id}`;
    const res = await request(url, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      },
      body: JSON.stringify(question)
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function createMultipleQuestions(token: string, resourceId: string, questions: any): Promise<IResponse | null>{
  try {
    const url = `/resources/${resourceId}/questions/multiple`;
    const res = await request(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      },
      body: JSON.stringify(questions)
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

