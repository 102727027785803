import { useContext, useEffect, useRef, useState } from "react";
import { Button, Container, H2, Input, TextEditor, EditorMethods, ActivityIndicator, RadioButton, GroupBox } from "typetailui";
import { IProgram, LANGUAGE } from "../../interfaces";
import { updateProgram } from "../../networking";
import GlobalContext from "../../helpers/context";
import { useNavigate, useLocation } from "react-router-dom";
import { isEmptyOrWhitespace } from "../../helpers/utils";
import { EditStatus } from "../../interfaces/EditStatus";

export default function UpdateProgram(){
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [program, setProgram] = useState<IProgram>(location.state);
  const {state} = useContext(GlobalContext);
  const editorRef = useRef<EditorMethods>(null);
  const navigate = useNavigate();
  const onUpdate = async () => {
    try {
      if (
        isEmptyOrWhitespace(program.name) ||
        isEmptyOrWhitespace(program.code)
      ) {
        return alert("Please input name and code.");
      }
      setLoading(true);
      const obj = {...program};
      if(editorRef.current){
        const description = {
          data: editorRef.current.getEditorContent(),
          text: editorRef.current.getEditorText()
        }
        obj.description = description;
      }
      const ret = await updateProgram(state.user.token, obj);
      if(ret && ret.success){
        alert("Program is updated successfully.");
        navigate(-1);
      }else{
        alert("Cannot update this program. Please try again later");
      }
    } catch (error) {
      alert("Cannot update this program. Please try again later");
    }
    setLoading(false);
  }
  const goBack = () => {
    navigate(-1);
  }
  useEffect(()=>{
    setLoading(false);
  }, []);
  const languages = Object.values(LANGUAGE).map(language => ({
    value: language,
    label: language
  }));
  const changeLanguage = (index: number) => {
    setProgram({...program, language: languages[index].value})
  }
  const statuses = Object.values(EditStatus).map(status => ({
    value: status,
    label: status
  }));
  const changeStatus = (index: number) => {
    setProgram({...program, status: statuses[index].value})
  }
  if(loading){
    return <ActivityIndicator/>
  }
  
  return(
    <Container className="justify-center space-y-1">
      <H2 className="text-center">Update Program {program.code}</H2>
      <Input placeholder="Name" onChangeText={text => setProgram({...program, name: text})} value={program.name} type="text" handleEnterKey={onUpdate}/>
      <GroupBox title="Language">
      {languages.map((language, index) =>
            <RadioButton
              key={index}
              index={index}
              name={"language"}
              selected={language.value === program.language}
              handleSelectionChange={index => changeLanguage(index)}
              label={language.label}
            />
        )}
      </GroupBox>
      
        <GroupBox title="Status">
        {statuses.map((status, index) =>
            <RadioButton
              key={index}
              index={index}
              name={"status"}
              selected={status.value === program.status}
              handleSelectionChange={index => changeStatus(index)}
              label={status.label}
            />
        )}
        </GroupBox>
        
      <TextEditor ref={editorRef} initial={program.description?.data}/>
      <Container className="flex flex-row justify-center">
        <Button title="Back" onClick={goBack} className="mx-2"/>
        <Button title="Update" onClick={onUpdate}/>
      </Container>
    </Container>
  )
}