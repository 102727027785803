import {IDescription} from "./IDescription";

export enum ResourceType{
  Studying = "studying",
  Homework = "homework",
  Quiz = "quiz",
  Exam = "exam"
}

export enum MimeType{
  Pdf = 'application/pdf',
  Mp3 = 'audio/mpeg',
  Mp4 = 'video/mp4',
  Jpeg = 'image/jpeg',
  Png = 'image/png',
  PlainText = 'text/plain',
  Html = 'text/html',
}
export interface IMedia{
  description: IDescription;
  url: string;
  mimeType: MimeType
}

export interface IResource {
  _id?: string;
  topic: string;
  order: number;
  type: ResourceType;//studying, homework, quiz, exam
  media?: IMedia;
  description?: IDescription;
  createdDate?: string;
  deleted?: boolean;
  passingGrade?: number;//it will determine to move to the next resource of this module
  estimatedTime?: number// //it should be in minutes
}