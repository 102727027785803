import { RouterProvider, createBrowserRouter } from "react-router-dom";
import React, {Suspense, lazy} from "react";
import { ActivityIndicator } from "typetailui";
const Login = lazy(() => import('../components/auth/Login'));
const Error = lazy(() => import('../components/NotFoundError'));

const router = createBrowserRouter([
  {
    path: "",
    element: (
      <Suspense fallback={<ActivityIndicator/>}>
        <Login />
      </Suspense>
    )
  },
  {
    path: "*",
    element: (
      <Suspense fallback={<ActivityIndicator/>}>
        <Error />
      </Suspense>
    )
  },
]);
export default function Auth() {
  return <RouterProvider router={router}/>;
}
