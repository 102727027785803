import { useContext, useState } from "react";
import {
  Container,
  H2,
  Button,
  ActivityIndicator,
  JsonEditor
} from "typetailui";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalContext from "../../helpers/context";
import { createMultipleQuestions } from "../../networking";
import { QuestionType } from "../../interfaces";
export default function UploadQuestions() {
  const { state } = useContext(GlobalContext);
  const [jsonData, setJsonData] = useState<any>({ name: "test" });
  const [content, setContent] = useState("");
  const location = useLocation();
  const { resourceId } = location.state;
  const navigate = useNavigate();

  const onUpload = async () => {
    try {
      const arr = content.split("\n");
      if (arr.length % 6 === 0) {
        const data = [];
        for (let i = 0; i < arr.length / 6; i++) {
          const start = i * 6;
          const correctAnswer: any =
            arr[start + 5].charCodeAt(8) - "A".charCodeAt(0) + 1;
          const options = [];
          for (let j = start + 1; j < start + 5; j++) {
            options.push({
              text: arr[j].substring(3),
              isCorrect: false
            });
          }
          options[correctAnswer-1].isCorrect = true;
          const item = {
            questionText: arr[start],
            order: i + 1,
            options: options,
            grade: 1,
            correctAnswer: {type: QuestionType.MCQ, answer: correctAnswer}
          };
          data.push(item);
        }
        const ret = await createMultipleQuestions(
          state.user.token,
          resourceId,
          data
        );
        if (ret && ret.success) {
          alert("Questions are uploaded successfully.");
          navigate(-1);
        } else {
          alert("Cannot upload questions. Please try again later.");
        }
      }
    } catch (error) {
      alert("Cannot upload questions. Please try again later.");
    }
  };

  if (state.loading) {
    return <ActivityIndicator />;
  }
  return (
    <Container className="justify-center space-y-1">
      <H2 className="text-center">Upload Question</H2>
      <Container className="flex flex-row justify-center space-x-2">
        <Button title="Back" onClick={() => navigate(-1)} />
        <Button title="Upload" onClick={onUpload} />
      </Container>
      {/* <JsonEditor jsonData={jsonData} setJsonData={setJsonData}/> */}
      <textarea
        className="border border-black w-full"
        rows={10}
        value={content}
        onChange={e => setContent(e.target.value)}
      />
    </Container>
  );
}
