import { EditStatus } from "../interfaces";

export function convertToMathString(str: string): string{
  return str.replace(/##/g, "\\");
}

export function isEmptyOrWhitespace(str: string): boolean {
  return str.trim() === "";
}

export function isValidEmail(email: string): boolean{
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function getBackgroundColor(editStatus: EditStatus){
  switch (editStatus) {
    case EditStatus.Archived:
      return 'bg-gray-400';
    case EditStatus.Published:
      return 'bg-green-400';
    case EditStatus.Review:
      return 'bg-orange-400'
    default:
      return 'bg-gray-500';
  }
}