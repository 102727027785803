import { useContext, useEffect, useRef, useState } from "react";
import {
  Container,
  H2,
  Input,
  TextEditor,
  EditorMethods,
  Button,
  RadioButton,
  ActivityIndicator,
  GroupBox,
  CheckBoxButton
} from "typetailui";
import { IClass, IProgram, IUser } from "../../interfaces";
import { useNavigate } from "react-router-dom";
import { isEmptyOrWhitespace } from "../../helpers/utils";
import { addClass, getMentors, getPrograms, getStudents } from "../../networking";
import GlobalContext from "../../helpers/context";

export default function AddClass() {
  const [data, setData] = useState<IClass>({ title: "", mentorId: "", studentIds: [], programId: "" });
  const [mentors, setMentors] = useState<IUser[]>([]);
  const [selectedMentor, setSelectedMentor] = useState(-1);
  const [programs, setPrograms] = useState<IProgram[]>([]);
  const [selectedProgram, setSelectedProgram] = useState(-1);
  const [students, setStudents] = useState<IUser[]>([]);
  const [selectedStudents, setSelectedStudents] = useState(new Set<number>());
  const [loading, setLoading] = useState(true);

  const editorRef = useRef<EditorMethods>(null);
  const { state } = useContext(GlobalContext);
  const navigate = useNavigate();

  const onAdd = async () => {
    try {
      if (isEmptyOrWhitespace(data.title)) {
        return alert("Please input title.");
      }
      if (isEmptyOrWhitespace(data.mentorId!)) {
        return alert("Please add an mentor.");
      }
      const studentIds: string[] = [];
      selectedStudents.forEach(value => {
        studentIds.push(students[value]._id!);
      });
      const obj = { ...data, studentIds };

      if (editorRef.current) {
        const description = {
          data: editorRef.current.getEditorContent(),
          text: editorRef.current.getEditorText()
        };
        obj.description = description;
      }
      const ret = await addClass(obj, state.user.token);
      if (ret && ret.success) {
        alert("Class is added successfully.");
        navigate(-1);
      } else {
        alert("Cannot add this class. Please try again later");
      }
    } catch (error) {
      alert("Cannot add this class. Please try again later");
    }
  };
  useEffect(() => {
    async function getData() {
      try {
        setLoading(true);
        let ret = await getMentors(state.user.token);
        if (ret.success) {
          setMentors(ret.data);
        }
        ret = await getPrograms(state.user.token);
        if (ret.success) {
          setPrograms(ret.data);
        }
        ret = await getStudents(state.user.token);
        if (ret.success) {
          setStudents(ret.data);
        }
      } catch (error) {}
      setLoading(false);
    }
    getData();
  }, []);
  const selectMentor = (index: number) => {
    setSelectedMentor(index);
    setData({ ...data, mentorId: mentors[index]._id });
  };
  const selectStudent = (index: number) => {
    let x = new Set<number>(Array.from(selectedStudents));
    if (x.has(index)) {
      x.delete(index);
    } else {
      x.add(index);
    }
    setSelectedStudents(x);
  };
  const selectProgram = (index: number) => {
    setSelectedProgram(index);
    setData({ ...data, programId: programs[index]._id });
  };
  if(loading){
    return <ActivityIndicator/>
  }
  return (
    <Container className="justify-center space-y-1">
      <H2 className="text-center">Add New Class</H2>
      <Input
        placeholder="Title"
        onChangeText={text => setData({ ...data, title: text })}
        value={data.title}
        type="text"
      />
      <GroupBox title="Select Mentor" className="m-2">
        <Container className="flex space-x-1">
          {mentors.map((mentor, index) =>
            <RadioButton
              key={index}
              index={index}
              name={"mentor"}
              selected={selectedMentor === index}
              handleSelectionChange={index => selectMentor(index)}
              label={mentor.email}
            />
          )}
        </Container>
      </GroupBox>
      <GroupBox title="Select Program" className="m-2">
        <Container className="flex flex-col space-x-1">
          {programs.map((program, index) =>
            <RadioButton
              key={index}
              index={index}
              name={"program"}
              selected={selectedProgram === index}
              handleSelectionChange={index => selectProgram(index)}
              label={program.name}
            />
          )}
        </Container>
      </GroupBox>
      <GroupBox title="Select Students" className="m-2">
        <Container className="flex flex-col space-x-1">
          {students.map((mentor, index) =>
            <CheckBoxButton
              key={index}
              index={index}
              prefix={"student"}
              selected={selectedStudents.has(index)}
              handleSelectionChange={index => selectStudent(index)}
              label={mentor.email}
            />
          )}
        </Container>
      </GroupBox>
      <TextEditor ref={editorRef} />
      <Container className="flex flex-row justify-center">
        <Button title="Back" onClick={() => navigate(-1)} className="mx-2" />
        <Button title="Add" onClick={onAdd} />
      </Container>
    </Container>
  );
}
