import { useLocation, useNavigate } from "react-router-dom";
import { Container, TextEditor, Button, Text } from "typetailui";
import { deleteCourse } from "../../networking";
import { useContext } from "react";
import GlobalContext from "../../helpers/context";

export default function CourseDetails() {
  const navigate = useNavigate();
  const { state} = useContext(GlobalContext);
  const location = useLocation();
  const { data, programId } = location.state;
  const gotoModuleList = () => {
    navigate(`/modules/${programId}/${data._id}`);
  };
  const handleDeleteCourse = async () => {
    try {
      const ret = await deleteCourse(state.user.token, programId, data._id);
      if (ret.success) {
        alert("this course is removed successfully.");
        navigate(-1);
      } else {
        alert("Cannot delete this course. Please try again later.");
      }
    } catch (error) {
      alert("Cannot delete this course. Please try again later.");
    }
  };
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container className="border-2 m-2 rounded">
      <Text>{`${data.code}: ${data.name}`}</Text>
      <Text>Priority: {data.order}</Text>
      {data.description &&
        <TextEditor readOnly={true} initial={data.description?.data} />}
      <Container className="flex flex-row justify-center space-x-2">
        <Button title="Back" onClick={goBack} />
        <Button title="Modules" onClick={gotoModuleList} />
        <Button title="Delete" onClick={handleDeleteCourse} />
      </Container>
    </Container>
  );
}
