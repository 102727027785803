import { useContext, useEffect } from "react";
import { deleteQuestion } from "../../networking";
import { Container, Button, ActivityIndicator, Select, RadioButton, Text } from "typetailui";
import GlobalContext from "../../helpers/context";
import { useLocation, useNavigate } from "react-router-dom";
import { IQuestion, QuestionType } from "../../interfaces";
import { convertToMathString } from "../../helpers/utils";

export default function QuestionDetails() {
  const { state } = useContext(GlobalContext);
  const location = useLocation();
  const question = location.state as IQuestion;
  const navigate = useNavigate();

  const handleDeleteQuestion = async () => {
    try {
      const ret = await deleteQuestion(
        state.user.token,
        question.resourceId,
        question._id!
      );
      if (ret && ret.success) {
        alert("This question is removed successfully.");
        navigate(-1);
      } else {
        alert("Cannot delete this question. Please try again later.");
      }
    } catch (error) {
      alert("Cannot delete this question. Please try again later.");
    }
  };
  const options = Object.values(QuestionType).map(type => ({
    value: type,
    label: type
  }));
  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise();
    }
  }, []);
  if (state.loading) {
    return <ActivityIndicator />;
  }
  return (
    <Container className="border-2 m-2 rounded">
      <Text>Question: {convertToMathString(question.questionText)}</Text>
      <Text>Priority: {question.order}</Text>
      <Text>Maximum Grade: {question.grade}</Text>
      <Text>Correct Answer: {question.correctAnswer.answer} </Text>
      <Select
        options={options}
        value={question.type}
        onChange={(val: any) => {}}
        disabled
      />
      {question.options &&
        question.options.map((option, index) =>
          <Container key={index} className="flex flex-row space-x-1">
            <RadioButton
              index={index}
              name={"question"}
              selected={option.isCorrect!}
              handleSelectionChange={index => {}}
              label={convertToMathString(option.text)}
              disabled
            />
          </Container>
        )}
      <Container className="flex flex-row justify-center space-x-1">
        <Button title="Back" onClick={() => navigate(-1)} />
        <Button title="Delete" onClick={handleDeleteQuestion} />
      </Container>
    </Container>
  );
}
