import { useLocation, useNavigate } from "react-router-dom";
import { Container, TextEditor, Button, Text } from "typetailui";
import { deleteResource } from "../../networking";
import { useContext } from "react";
import GlobalContext from "../../helpers/context";

export default function ResourceDetails() {
  const navigate = useNavigate();
  const { state } = useContext(GlobalContext);
  const location = useLocation();
  const { data, moduleId, courseId } = location.state;
  const gotoQuestionList = () => {
    navigate(`/questions/${data._id}`);
  };
  const handleDeleteResource = async () => {
    try {
      const ret = await deleteResource(
        state.user.token,
        courseId,
        moduleId,
        data._id!
      );
      if (ret && ret.success) {
        alert("This resource is removed successfully.");
        navigate(-1);
      } else {
        alert("Cannot delete this course. Please try again later.");
      }
    } catch (error) {
      alert("Cannot delete this course. Please try again later.");
    }
  };
  return (
    <Container className="border-2 m-2 rounded">
      <Text>{data.topic}</Text>
      <Text>Priority: {data.order}</Text>
      {data.description &&
        <TextEditor readOnly={true} initial={data.description?.data} />}
      <Container className="flex flex-row justify-center space-x-2">
        <Button title="Back" onClick={() => navigate(-1)} />
        <Button title="Questions" onClick={gotoQuestionList} />
        <Button title="Delete" onClick={handleDeleteResource} />
      </Container>
    </Container>
  );
}
