export enum UserRole{
  Student = "student",
  Mentor = "mentor",
  //Admin = "admin",
}
export interface IUser{
  _id?: string;
  email: string;
  password: string;
  role?: UserRole;
}