import { IModule, IResponse } from "../interfaces";
import request from "./common";
export async function getModules(token: string, courseId: string): Promise<IResponse>{
  try {
    const url = `/courses/${courseId}/modules`;
    const res = await request(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function createModule(token: string, courseId: string, module: IModule): Promise<IResponse | null>{
  try {
    const url = `/courses/${courseId}/modules`;
    const res = await request(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      },
      body: JSON.stringify(module)
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function deleteModule(token: string, courseId: string, moduleId: string): Promise<IResponse>{
  try {
    const url = `/courses/${courseId}/modules/${moduleId}`;
    const res = await request(url, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function updateModule(token: string, module: IModule): Promise<IResponse>{
  try {
    const url = `/courses/${module.courseId}/modules/${module._id}`;
    const res = await request(url, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      },
      body: JSON.stringify(module)
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}