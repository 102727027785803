import { useContext, useEffect, useRef, useState } from "react";
import { IMedia, IResource, MimeType, ResourceType } from "../../interfaces";
import { Container, Button, H2, Input, TextEditor, EditorMethods, ActivityIndicator, Select, NumericInput  } from "typetailui";
import { getResource, updateResource } from "../../networking";
import GlobalContext from "../../helpers/context";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmptyOrWhitespace } from "../../helpers/utils";

export default function UpdateResource(){
  const location = useLocation();
  const {data, courseId, moduleId} = location.state;
  const [resource, setResource] = useState<IResource>({topic: "", type: ResourceType.Studying, order: 0, estimatedTime: 0,
  media: {
    mimeType: MimeType.PlainText,
    url: "",
    description: {
      data: "",
      text: ""
    }
  }});
  const [loading, setLoading] = useState(true);
  const {state} = useContext(GlobalContext);
  const editorRef = useRef<EditorMethods>(null);
  const mediaRef = useRef<EditorMethods>(null);
  const navigate = useNavigate();
  const onUpdate = async () => {
    try {
      if(isEmptyOrWhitespace(resource.topic) || isEmptyOrWhitespace(String(resource.order))){
        return alert("Please input topic and order.");
      }
      const obj = {...resource};
      if(editorRef.current){
        const description = {
          data: editorRef.current.getEditorContent(),
          text: editorRef.current.getEditorText()
        }
        obj.description = description;
      }
      if(mediaRef.current){
        const media: IMedia = {...resource.media!};
        media.description = {
          data: mediaRef.current.getEditorContent(),
          text: mediaRef.current.getEditorText()
        }
        obj.media = media;
        console.log(obj);
      }
      const ret = await updateResource(state.user.token, courseId, moduleId, obj);
      if(!ret || !ret.success){
        alert("Cannot update this resource. Please try again later");
      }else{
        alert("Resource is updated successfully.");
        navigate(-1);
      }
    } catch (error) {
      alert("Cannot update this resource. Please try again later");
    }
  }
  const goBack = () => {
    navigate(-1);
  }
  async function getData(){
    try {
      setLoading(true);
      const res = await getResource(state.user.token, courseId, moduleId, data._id);
      if(res && res.success){
        console.log(res.data)
        setResource({...resource, ...res.data});
      }
    } catch (error) {
      
    }
    setLoading(false);
  }
  useEffect(() => {
    getData();
  }, []);
  if(loading){
    return <ActivityIndicator/>
  }
  const options = Object.values(ResourceType).map((type) => ({
    value: type,
    label: type,
  }));
  const mediaTypes = Object.values(MimeType).map(type => ({
    value: type,
    label: type
  }));
  const changeMediaType = (type: MimeType) => {
    const obj = {...resource};
    obj.media!.mimeType = type;
    setResource(obj);
  }
  const changeMediaURL = (url: string) => {
    const obj = {...resource};
    obj.media!.url = url;
    setResource(obj);
  }
  return(
    <Container className="justify-center space-y-1">
      <H2 className="text-center">Update Resource</H2>
      <Input placeholder="topic" onChangeText={text => setResource({...resource, topic: text})} value={resource.topic} type="text" label="Topic"/>
      <NumericInput onChangeNumber={number => setResource({...resource, order: number})} placeholder="Order" initial={String(resource.order)} label="Order"/>
      <NumericInput onChangeNumber={number => setResource({...resource, passingGrade: number})} placeholder="Passing Grade" initial={String(resource.passingGrade? resource.passingGrade: "")} label="Passing Estimation"/>
      <NumericInput onChangeNumber={number => setResource({...resource, estimatedTime: number})} placeholder="Time Estimation" initial={String(resource.estimatedTime?resource.estimatedTime: "")} handleEnterKey={onUpdate} label="Time Estimation"/>
      <Select options={options} value={resource.type} onChange={(val) => setResource({...resource, type: val})} label="Type"/>
      {
        resource.media && 
        <Container className="justify-center space-y-1">
        <Select options={mediaTypes} value={resource.media?.mimeType} onChange={val => changeMediaType(val)} label={"Media Type"}/>
        <Input type="text" placeholder="URL" value={resource.media?.url!} onChangeText={url => changeMediaURL(url)} label="URL"/>
        <TextEditor ref={mediaRef} initial={resource.media?.description.data}/>
      </Container>
      }
      <TextEditor ref={editorRef} initial={resource.description?.data}/>
      <Container className="flex flex-row justify-center space-x-2">
        <Button title="Back" onClick={goBack}/>
        <Button title="Update" onClick={onUpdate}/>
      </Container>
    </Container>
  )
}