import { IProgram, IResponse } from "../interfaces";
import request from "./common";
export async function getPrograms(token: string): Promise<IResponse>{
  try {
    const url = "/programs";
    const res = await request(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function addProgram(token: string, program: IProgram): Promise<IResponse>{
  try {
    const url = "/programs";
    const res = await request(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(program)
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function deleteProgram(token: string, programId: string): Promise<IResponse>{
  try {
    const url = `/programs/${programId}`;
    const res = await request(url, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function updateProgram(token: string, program: IProgram): Promise<IResponse>{
  try {
    const url = `/programs/${program._id}`;
    const res = await request(url, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(program)
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}