import { ICourse, IResponse } from "../interfaces";
import request from "./common";

export async function getCourses(token: string, programId: string): Promise<IResponse>{
  try {
    const url = `/programs/${programId}/courses`;
    const res = await request(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function getCourse(token: string, programId: string, courseId: string): Promise<IResponse>{
  try {
    const url = `/programs/${programId}/courses/${courseId}`;
    const res = await request(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function addCourse(token: string, programId: string, course: ICourse): Promise<IResponse>{
  try {
    const url = `/programs/${programId}/courses`;
    const res = await request(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(course)
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function deleteCourse(token: string, programId: string, courseId: string): Promise<IResponse>{
  try {
    const url = `/programs/${programId}/courses/${courseId}`;
    const res = await request(url, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}

export async function updateCourse(token: string, programId: string, course: ICourse): Promise<IResponse>{
  try {
    const url = `/programs/${programId}/courses/${course._id}`;
    const res = await request(url, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(course)
    });
    return res;
  } catch (error) {
    
  }
  return {success: false, data: "Cannot connect to server"};
}