import { EditStatus } from "./EditStatus";
import { ICourse } from "./ICourse";
import {IDescription} from "./IDescription";
export enum LANGUAGE {
  VN = "Vietnamese",
  EN = "English"
}
export interface IProgram {
  _id?: any;
  name: string;
  code: string;//readable code
  createdDate?: string;
  courses?: ICourse[];
  image?: string;
  description?: IDescription;
  language?: LANGUAGE;
  status?: EditStatus;
}