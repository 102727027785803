import { useState, useContext } from "react";
import {
  Container,
  H2,
  Input,
  NumericInput,
  Button,
  ActivityIndicator,
  Select,
  RadioButton
} from "typetailui";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalContext from "../../helpers/context";
import { IQuestion, QuestionType, IOption } from "../../interfaces";
import { createQuestion } from "../../networking";
import { isEmptyOrWhitespace } from "../../helpers/utils";
export default function AddQuestion() {
  const { state } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const {resourceId, order} = location.state;
  const [question, setQuestion] = useState<IQuestion>({
    questionText: "",
    order,
    type: QuestionType.MCQ,
    resourceId: resourceId,
    options: [],
    grade: 1,
    correctAnswer: {type: QuestionType.MCQ, answer: -1}
  });
  const goBack = () => {
    navigate(-1);
  };
  const onAdd = async () => {
    try {
      if (
        isEmptyOrWhitespace(question.questionText) ||
        isEmptyOrWhitespace(String(question.grade)) ||
        isEmptyOrWhitespace(String(question.order))
      ) {
        return alert("Please input question, grade, and order.");
      }
      if(question.correctAnswer.answer === -1){
        alert("Please set the correct answer for this question.")
        return;
      }
      const ret = await createQuestion(state.user.token, question.resourceId, question);
      if(ret && ret.success){
        alert("Question is added successfully.");
        navigate(-1);
      }else{
        alert("Cannot add this question. Please try again later");
      }
    } catch (error) {
      alert("Cannot add this question. Please try again later");
    }
  };
  const onAddOption = () => {
    let arr: IOption[] = [];
    if (question.options) {
      arr = [...question.options];
    }
    arr.push({ text: "", isCorrect: false });
    setQuestion({ ...question, options: arr });
  };
  const changeOptionText = (index: number, text: string) => {
    let arr: IOption[] = [...question.options!];
    arr[index].text = text;
    setQuestion({ ...question, options: arr });
  };
  const changeOptionCorrection = (index: number) => {
    let arr: IOption[] = [...question.options!];
    for(let i = 0; i < arr.length; i++){
      if(i === index){
        arr[i].isCorrect = true;
      }else{
        arr[i].isCorrect = false;
      }
    }
    const correct = {...question.correctAnswer, answer: index + 1};
    setQuestion({ ...question, options: arr, correctAnswer: correct});
  };
  const deleteOption = (index: number) => {
    let arr: IOption[] = [...question.options!];
    arr.splice(index, 1);
    const correct = {...question.correctAnswer};
    if(correct.answer === index + 1){
      correct.answer = -1;
    }
    setQuestion({ ...question, options: arr, correctAnswer: correct });
  };
  const options = Object.values(QuestionType).map(type => ({
    value: type,
    label: type
  }));
  if (state.loading) {
    return <ActivityIndicator />;
  }
  return (
    <Container className="justify-center space-y-1">
      <H2 className="text-center">Add New Question</H2>
      <Select
        options={options}
        value={question.type}
        onChange={(val: any) => setQuestion({ ...question, type: val })}
        disabled
      />
      <NumericInput
        onChangeNumber={number => setQuestion({ ...question, order: number })}
        placeholder="Order"
        initial={String(question.order)}
      />
      <NumericInput
        onChangeNumber={number => setQuestion({ ...question, grade: number })}
        placeholder="Grade"
        initial={String(question.grade)}
      />
      <Input
        placeholder="Question"
        onChangeText={text => setQuestion({ ...question, questionText: text })}
        value={question.questionText}
        type="text"
        handleEnterKey={onAddOption}
      />

      {question.options &&
        question.options.map((option, index) =>
          <Container key={index} className="flex flex-row space-x-1">
            <Input
              type="text"
              placeholder="New Option"
              value={option.text}
              onChangeText={text => changeOptionText(index, text)}
              handleEnterKey={onAddOption}
            />
            <RadioButton
              index={index}
              name={"question"}
              selected={option.isCorrect!}
              handleSelectionChange={index => changeOptionCorrection(index)}
              label="Correct"
            />
            <Button title="Delete" onClick={() => deleteOption(index)} />
          </Container>
        )}
      <Container className="flex flex-row justify-center space-x-2">
        <Button title="Add Option" onClick={onAddOption} />
        <Button title="Back" onClick={goBack} />
        <Button title="Add" onClick={onAdd} />
      </Container>
    </Container>
  );
}
