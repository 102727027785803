import { useLocation, useNavigate } from "react-router-dom";
import { ActivityIndicator, Button, Container, TextEditor, Text } from "typetailui";
import { deleteProgram } from "../../networking";
import { useContext, useEffect, useState } from "react";
import GlobalContext from "../../helpers/context";
export default function ProgramDetails() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {state} = useContext(GlobalContext);
  const location = useLocation();
  const data = location.state;
  const gotoCourseList = () => {
    navigate(`/courses/${data._id}`);
  }
  const handleDeleteProgram = async() => {
    try {
      setLoading(true);
      const ret = await deleteProgram(state.user.token, data._id);
      if(ret.success){
        alert("The program is removed successfully.");
        navigate(-1);
      }else{
        alert("Cannot delete this program. Please try again later.");
      }
    } catch (error) {
      alert("Cannot delete this program. Please try again later.");
    }
    setLoading(false);
  }
  const goBack = () => {
    navigate(-1);
  }
  useEffect(() => {
    setLoading(false);
  }, [])
  if(loading){
    return <ActivityIndicator/>
  }
  return (
    <Container className="border-2 m-2 rounded">
      <Text>{`${data.code}: ${data.name}`}</Text>
      {data.description && <TextEditor readOnly={true} initial={data.description?.data}/>}
      <Container className="flex flex-row justify-center">
        <Button title="Back" onClick={goBack} className="mx-2"/>
        <Button title="Courses" onClick={gotoCourseList} className="mx-2"/>
        <Button title="Delete" onClick={handleDeleteProgram}/>
      </Container>
    </Container>
  );
}
