import {IDescription} from "./IDescription";

export enum QuestionType {
  MCQ = "multiple choice",
  Essay = "essay",
  Programming = "programming"
}

export interface IOption {
  text: string;
  isCorrect?: boolean; // This can be omitted for non-MCQ type questions
}

export interface IQuestion {
  _id?: string;
  resourceId: string;
  questionText: string;
  order: number;
  options?: IOption[]; // Used for MCQs. For essay or programming, this could be null or empty.
  correctAnswer?: any; // Can be an index, text, or even code snippet depending on the question type.
  type: QuestionType;
  additionalInfo?: {
    // Any additional information needed for specific types of questions like word limit for essays,
    // programming language for coding questions, etc.
    wordLimit?: number;
    programmingLanguage?: string;
  };
  deleted?: boolean;
  grade?: number;
  createdDate?: string;
  description?: IDescription;
}