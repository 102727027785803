import { useContext, useEffect, useState } from "react";
import { Button, Container, H2, Input, ActivityIndicator, RadioButton } from "typetailui";
import { IUser, UserRole } from "../../interfaces";
import { addUser } from "../../networking";
import GlobalContext from "../../helpers/context";
import { useNavigate } from "react-router-dom";
import { isEmptyOrWhitespace, isValidEmail } from "../../helpers/utils";

export default function AddUser(){
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUser>({email: "", password: "", role: UserRole.Student});
  const [repeatPassword, setRepeatPassword] = useState("")
  const {state} = useContext(GlobalContext);
  const navigate = useNavigate();
  
  const onAdd = async () => {
    try {
      if(isEmptyOrWhitespace(user.email) || isEmptyOrWhitespace(user.password)){
        return alert("Please provide email and password");
      }
      if(user.password !== repeatPassword){
        return alert("Password mismatch. Please check again.")
      }
      if(!isValidEmail(user.email)){
        return alert("Please provide the correct email");
      }
      
      setLoading(true);
      const ret = await addUser(state.user.token, user);
      if(ret && ret.success){
        alert("User is added successfully.");
        navigate(-1);
      }else{
        alert(ret.data);
      }
    } catch (error) {
      alert("Cannot add this user. Please try again later");
    }
    setLoading(false);
  }
  const goBack = () => {
    navigate(-1);
  }
  useEffect(() => {
    setLoading(false);
  }, [])
  if(loading){
    return <ActivityIndicator/>
  }
  const roles = Object.values(UserRole).map(role => ({
    value: role,
    label: role
  }));
  const changeRole = (index: number) => {
    setUser({...user, role: roles[index].value})
  }
  return(
    <Container className="justify-center space-y-1">
      <H2 className="text-center">Add New User</H2>
      <Input placeholder="Email" onChangeText={text => setUser({...user, email: text})} value={user.email} type="email"/>
      <Input placeholder="Password" onChangeText={text => setUser({...user, password: text})} value={user.password} type="password"/>
      <Input placeholder="Repeat Password" onChangeText={setRepeatPassword} value={repeatPassword} type="password" handleEnterKey={onAdd}/>
      {roles.map((role, index) =>
            <RadioButton
              key={index}
              index={index}
              name={"role"}
              selected={role.value === user.role}
              handleSelectionChange={index => changeRole(index)}
              label={role.label}
            />
        )}
      <Container className="flex flex-row justify-center">
        <Button title="Back" onClick={goBack} className="mx-2"/>
        <Button title="Add" onClick={onAdd}/>
      </Container>
    </Container>
  )
}